<script lang="ts" setup>
// vue
import { computed } from 'vue'

// vuetify
import { useDisplay } from 'vuetify'

/**
 * props
 * ==================================================================
 */
interface Props {
  maxWidth?: number
  show: boolean
  textClass?: string
  titleClass?: string
}
const props = withDefaults(
  defineProps<Props>(),
  {
    maxWidth: 500,
    textClass: '',
    titleClass: ''
  }
)

/**
 * emitted events
 * ==================================================================
 */
const emit = defineEmits(['toggle-dialog'])

/**
 * vuetify
 * ==================================================================
 */
const { xs } = useDisplay()

/**
 * computed
 * ==================================================================
 */
const toggleDialog = computed({
  get () {
    return props.show
  },
  set (val: boolean) {
    emit('toggle-dialog', val)
  }
})
</script>

<template>
  <v-dialog
    v-model="toggleDialog"
    :max-width="props.maxWidth"
  >
    <v-card>
      <v-card-title
        class="d-flex justify-end pb-0"
        :class="props.titleClass"
      >
        <v-btn
          icon
          color="grey"
          data-test="btn-close-dialog"
          variant="plain"
          @click="toggleDialog = false"
        >
          <v-icon :size="xs ? 40 : undefined">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text :class="`${props.textClass} pt-0`">
        <slot name="content" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
